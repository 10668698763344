/* #pick-country::before {
  content: '';
  display: block;
  height: 74px;
} */

#pick-country {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-select {
  display: flex;
  flex-direction: column;
  align-items: center;
}

select {
  width: 40rem;
  font-size: 1.6rem;
  font-family: inherit;
  padding: 1.6rem;
  font-weight: 600;
  background-color: #f2f2f2;
  border-radius: 0.8rem;
  border: 2px solid #cccccc;
  color: #1a1a1a;
}

select:focus {
  border: 2px solid #009eff;
  outline: none;
}

.btn-navigate {
  background-color: #009eff;
  color: #ffffff;
  margin-top: 3.2rem;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-out;
}

.btn-navigate:hover {
  transition: all 0.2s ease-out;
  transform: scale(1.05);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}

.btn-navigate:active {
  background-color: #00ea37;
}

@media (max-width: 575px) {
  select {
    width: 28rem;
    font-size: 1.4rem;
    padding: 1.2rem;
  }
}
