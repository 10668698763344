#footer {
  position: relative;
  background-color: #1a1a1a;
  max-width: 256rem;
  margin: 40rem auto 0;
}

#footer::before {
  content: '';
  display: block;
  height: 13rem;
  background-color: #1a1a1a;
}

.footer-container {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  row-gap: 6.4rem;
  text-transform: uppercase;
}

.pd-btm-sm {
  padding-bottom: 3.2rem;
}

.link-container1 {
  display: flex;
  justify-content: space-evenly;
}

.link-container2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6.4rem;
  align-items: center;
}

.privacy-terms-container {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.link-container2 .nav-link {
  margin-bottom: 0;
  font-size: 1.2rem;
}
.towerbuddy-logo-container {
  width: 18rem;
}

.towerbuddy-logo {
  width: 100%;
  height: auto;
}

.map-container {
  width: 100%;
  height: 15rem;
  overflow: hidden;
}

.map {
  width: 100%;
  height: 60rem;
  object-fit: cover;
  transform: translateY(-30%) scale(1.5);
}

#footer p {
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  color: #ffffff;
}

#footer span {
  font-size: 1.4rem;
  font-weight: 500;
  color: #828282;
  margin: 0 0.8rem;
}

.nav-lists {
  list-style: none;
}

.nav-link-text {
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  margin-bottom: 0 !important;
  color: #828282 !important;
  display: inline-block;
}

.nav-link {
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 500;
  color: #828282;
  font-family: inherit;
  display: inline-block;
  margin-bottom: 1.2rem;
  transition: all 0.2s ease-out;
}

.nav-link:hover {
  color: #009eff;
  transition: all 0.2s ease-out;
  transform: scale(1.06);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 2px;
}

.nav-link:active {
  color: #00ea37;
}

.social-links-container {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.social-links-container .nav-link {
  margin-bottom: 0;
}

.social-icon {
  font-size: 2.6rem;
}

.fb {
  padding: 0.25rem;
}

@media (max-width: 2200px) {
  #footer::before {
    height: 7rem;
  }
}

@media (max-width: 2000px) {
  #footer {
    margin: 36rem auto 0;
  }
  #footer::before {
    height: 4.5rem;
  }
}

@media (max-width: 1900px) {
  #footer::before {
    height: 3rem;
  }
}
@media (max-width: 1800px) {
  #footer {
    margin: 32rem auto 0;
  }
  #footer::before {
    height: 2rem;
  }
}
@media (max-width: 1700px) {
  #footer::before {
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 1500px) {
  #footer {
    margin: 30rem auto 0;
  }
}

@media (max-width: 1300px) {
  #footer {
    margin: 26rem auto 0;
  }
}

@media (max-width: 1050px) {
  #footer {
    margin: 22rem auto 0;
  }
}
@media (max-width: 1024px) {
  #footer p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }

  #footer span {
    font-size: 1.2rem;
    margin: 0 0.6rem;
  }
  .towerbuddy-logo-container {
    width: 16rem;
  }
  .footer-container {
    row-gap: 4.8rem;
  }
  .link-container1 {
    gap: 2.4rem !important;
  }
  .nav-link-text {
    font-size: 1.2rem !important;
  }

  .nav-link {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .social-icon {
    font-size: 2.2rem;
  }
}

@media (max-width: 900px) {
  .towerbuddy-logo {
    width: 14rem;
  }
  .link-container1 {
    flex-direction: column;
    gap: 3.2rem;
    align-items: center;
    justify-content: center;
  }
  .link-container1 .nav-list,
  .link-container1 p {
    text-align: center;
  }
  .social-links-container {
    justify-content: center;
  }
}

@media (max-width: 540px) {
  .privacy-terms-container span {
    display: none;
    visibility: hidden;
  }
  .privacy-terms-container {
    flex-direction: column;
  }
  .nav-list span {
    display: none;
    visibility: hidden;
  }
  .nav-list .nav-link {
    display: block;
  }
}

@media (max-width: 428px) {
  #footer {
    /* margin: 12.6rem auto 0; */
  }
  .towerbuddy-logo-container {
    width: 16rem;
  }
}
