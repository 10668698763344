.container {
  max-width: 120rem;
  margin: 0rem auto;
  padding: 8rem 3.2rem;
}

@media (max-width: 1024px) {
  .container {
    padding: 6.4rem 2.4rem;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 6.4rem 2.4rem;
  }
}

@media (max-width: 425px) {
  .container {
    padding: 6.4rem 1.2rem;
  }
}
