.container-navbar {
  max-width: 140rem !important;
  padding: 0 !important;
}

.container-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  user-select: none;
}

.logo {
  height: 6rem;
  width: auto;
  cursor: pointer;
}

.nav-container {
  width: 100%;
}

.navlists {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  gap: 6.4rem;
}

.navlink {
  text-decoration: none;
  font-size: 1.6rem;
  display: inline-block;
  color: hsl(0, 0%, 60%);
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.navlink:hover {
  transition: all 0.2s ease-out;
  color: #1a1a1a;
}

.active {
  color: #009eff;
  transition: all 0.8s;
}
