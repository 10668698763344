.header {
  background-color: #ffffff;
  height: auto;
  width: 100vw;
  padding: 0.7rem 6.4rem !important;
  position: fixed;
  top: -84px;
  z-index: 4;
  -webkit-box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 8px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  transition: top 0.4s ease-out;
}

.show-nav {
  transition: top 0.4s ease-out;
  top: 0px;
}

.header-mobile {
  padding: 0.6rem 3.2rem !important;
}
