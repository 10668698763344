button {
  padding: 1.2rem 2.4rem;
  font-size: 1.6rem;
  font-weight: 700;
  border: none;
  font-family: inherit;
  border-radius: 0.6rem;
  cursor: pointer;
  user-select: none;
}
