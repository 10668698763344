.container-navbar-mobile {
  max-width: 140rem !important;
  padding: 0 !important;
}

.container-nav-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3.2rem;
  user-select: none;
}

.logo-mobile {
  height: 5rem;
  width: auto;
  cursor: pointer;
}

.nav-container-mobile {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.3s ease-in;
}

.mobile-nav-hidden {
  transition: all 0.3s ease-out;
  left: 100vw;
}

.navlists-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  height: 100%;
  list-style: none;
}

.navlink-mobile {
  text-decoration: none;
  font-size: 2rem;
  display: inline-block;
  color: hsl(0, 0%, 60%);
  font-weight: 700;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.navlink-mobile:hover {
  transition: all 0.2s ease-out;
  color: #1a1a1a;
}

.active {
  color: #009eff;
  transition: all 0.8s;
}

.mobile-nav-icons {
  font-size: 4.4rem;
  color: #666666;
  transition: 0.2s ease-out;
}

.mobile-nav-icons:hover {
  color: #1a1a1a;
}

.mobile-nav-icons:active {
  color: #009eff;
}
