.cookies-container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 2.4rem;
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 10;
  outline: 1px solid #e6e6e6;
}

.cookies-paragraph {
  font-family: inherit;
  font-size: 1.6rem;
  text-align: center;
  max-width: 600px;
  font-weight: 500;
  color: #666666;
}

.accept {
  font-weight: 600;
  color: #1a1a1a;
}

.cookies-container-btns {
  display: flex;
  gap: 2.4rem;
}

.btn-accept {
  background-color: #009eff;
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
  transition: all 0.2s;
}
.btn-accept:hover {
  background-color: #00ea37;
  color: #fff;
  font-weight: 600;
  font-size: 1.6rem;
  transition: all 0.2s;
}
.btn-reject {
  background-color: #fff;
  color: #009eff;
  font-weight: 600;
  font-size: 1.6rem;
  transition: all 0.2s;
  border: 2px solid #009eff;
}
.btn-reject:hover {
  background-color: #fff;
  color: #00ea37;
  font-weight: 600;
  font-size: 1.6rem;
  border: 2px solid #00ea37;
  transition: all 0.2s;
}

@media (max-width: 428px) {
  .cookies-container {
    gap: 1.2rem;
  }
  .cookies-paragraph {
    font-size: 1.4rem;
  }
  .cookies-container-btns {
    gap: 1.2rem;
  }
  .btn-accept {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }
  .btn-reject {
    font-size: 1.4rem;
    padding: 1rem 2rem;
  }
}
