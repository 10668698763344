.svg {
  width: 100%;
  position: absolute;
  top: -160px;
  height: 15rem;
  z-index: 1;
}

.svg-1 {
  position: absolute;
  z-index: -3;
}

.svg-2 {
  position: absolute;
  z-index: -2;
}
.svg-3 {
  position: absolute;
  z-index: -1;
}

@media (max-width: 1500px) {
  .svg {
    top: -140px;
  }
}

@media (max-width: 1300px) {
  .svg {
    top: -110px;
  }
}

@media (max-width: 1050px) {
  .svg {
    top: -80px;
  }
}

@media (max-width: 600px) {
  .svg {
    top: -50px;
  }
}
@media (max-width: 500px) {
  .svg {
    top: -40px;
  }
}
@media (max-width: 400px) {
  .svg {
    top: -20px;
  }
}
