* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

.title-secondary {
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
  color: #1a1a1a;
  margin-bottom: 6.4rem;
}

@media (max-width: 1024px) {
  .title-secondary {
    margin-bottom: 8rem;
  }
}

@media (max-width: 768px) {
  .title-secondary {
    margin-bottom: 6.4rem;
  }
}

@media (max-width: 428px) {
  .title-secondary {
    font-size: 2.4rem;
    margin-bottom: 3.2rem;
  }
}

/* --- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
  10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 /  44 / 52 / 62 / 74 / 86 / 98

--- 02 WHITESPACE

- Spacing system (px)
   2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

--- 03 FONT WEIGHT

-Light 300
-Regular 400
-Bold 500

--- 04 Line height

 -Reset line-height: 0.7;
- Line height paragraph - 2.6rem for FS 1.6rem
paragraph - 2.2rem for FS 1.4rem

--- 05 Letter Spacing

-Title -0.2px
-Paragraph 0.2px


--- 06 Colors

#007ECC
#009EFF
#33B1FF
#4AD3BC
#4DF073
#00EA37
#00BB2C
#1A1A1A
#666666
#CCCCCC
#e6e6e6
#f2f2f2
#FFFFFF


- */
